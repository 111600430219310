body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	font-family: 'Source Sans 3', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

::-webkit-scrollbar {
	width: 0.425em;
	height: 0.425em;
	opacity: 0;
}

/* ::-webkit-scrollbar-track {

} */

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 0.425em;
}
